<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { Icon } from "@iconify/vue2";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Araç Listesi",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Icon },
  data() {
    return {
      isBusy: false,
      isButton: false,
      title: "Araç Listesi",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Araç Listesi",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      fields: [
        { key: "plate", sortable: true, label: "Plaka" },
        { key: "model", sortable: true, label: "Model" },
        { key: "model_year", sortable: true, label: "Model Yılı" },
        {
          key: "status",
          sortable: true,
          label: "Durum",
          formatter: (value) => {
            return value === 1 ? "Aktif" : "Satıldı";
          },
        },
        { key: "actions", label: "İşlemler" },
      ],
    };
  },
  methods: {
    getData(ctx, callback) {
      const params = "?page=" + ctx.currentPage;
      axios
        .get(process.env.VUE_APP_BASEURL + "/vehicle" + params, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const items = response.data.data;
            this.totalRows = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            callback(items);
          }
        })
        .catch((error) => {
          callback([]);
          console.error("Veri yüklenirken bir hata oluştu:", error);
        });
      this.isBusy = false;
      return;
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    edit(index) {
      this.$router.push(`/vehicle/edit/${index}`);
    },
    del(index) {
      this.isButton = true
      const api_url = process.env.VUE_APP_BASEURL + `/vehicle/${index}`;
      axios
        .delete(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$refs.dataTable.refresh();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isButton = false
        });
    },
    sorgula() {
      this.$refs.dataTable.refresh();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-between align-items-center mb-3"
              >
                <h4 class="card-title m-0">Araç Listesi</h4>
                <router-link to="/vehicle/add" class="btn btn-primary">
                  <Icon icon="lucide:plus-circle" class="mr-1" /> Araç Ekle
                </router-link>
              </div>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table ref="dataTable" :items="getData" :fields="fields" :per-page="10" :total-rows="totalRows"
                responsive="sm" :current-page="currentPage" :busy.sync="isBusy">
                <template #table-busy>
                  <div class="d-flex flex-column align-items-center justify-content-center gap-1 my-2">
                    <Icon icon="lucide:loader" class="spinner-rotate" :style="{ fontSize: '72px' }" />
                  </div>
                </template>
                <template #cell(purchase_date)="data">
                  {{ data.item.updated_at | moment("DD.MM.YYYY") }}
                </template>
                <template v-slot:cell(actions)="data">
                  <button @click="edit(data.item.id)" :disabled="isButton" class="btn btn-sm btn-secondary">
                    Düzenle
                  </button>
                  <button @click="del(data.item.id)" :disabled="isButton" class="btn btn-sm btn-danger ml-2">
                    Sil
                  </button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="onPageChange"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
